var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "full", pageTitle: _vm.pageTitle } },
    [
      _c(
        "vx-card",
        [
          _vm.isEditForm
            ? _c("div", { staticClass: "vx-row mb-6" }, [
                _c("div", { staticClass: "vx-col w-full align-center" }, [
                  _c("span", [_vm._v("Deposit Rule Id")]),
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { name: "id", disabled: true },
                      model: {
                        value: _vm.form.id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "id", $$v)
                        },
                        expression: "form.id",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
              _c("div", { staticClass: "vx-row mb-6" }, [
                _c("div", { staticClass: "vx-col w-full align-center" }, [
                  _c("span", [_vm._v("Deposit Rule Name*")]),
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|max:100",
                          expression: "'required|max:100'",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: { name: "name", disabled: _vm.readOnly },
                      model: {
                        value: _vm.form.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "name", $$v)
                        },
                        expression: "form.name",
                      },
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("name"))),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
              _c("div", { staticClass: "vx-row mb-6" }, [
                _c("div", { staticClass: "vx-col w-full" }, [
                  _c("span", [_vm._v("Priority")]),
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "min_value:1|max_value:100",
                          expression: "'min_value:1|max_value:100'",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: {
                        type: "number",
                        name: "priority",
                        disabled: _vm.readOnly,
                      },
                      model: {
                        value: _vm.form.priority,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "priority", $$v)
                        },
                        expression: "form.priority",
                      },
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("priority"))),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
              _c("div", { staticClass: "vx-row mb-6" }, [
                _c("div", { staticClass: "vx-col w-full" }, [
                  _c("span", [_vm._v("Enable Rule:")]),
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("vs-switch", {
                      staticClass: "mt-2",
                      attrs: { disabled: _vm.readOnly },
                      model: {
                        value: _vm.form.isActive,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "isActive", $$v)
                        },
                        expression: "form.isActive",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
              _c("div", { staticClass: "vx-row mb-6" }, [
                _c("div", { staticClass: "vx-col w-full" }, [
                  _c("span", [_vm._v("Deposit Options:")]),
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        multiple: "",
                        name: "Deposit Options",
                        options: _vm.priceRulesFormData.depositOptions,
                        reduce: (x) => x.code,
                        disabled: _vm.readOnly,
                      },
                      model: {
                        value: _vm.form.depositOptionId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "depositOptionId", $$v)
                        },
                        expression: "form.depositOptionId",
                      },
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("Deposit Options"))),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
              _c("div", { staticClass: "vx-row mb-6" }, [
                _c("div", { staticClass: "vx-col w-full" }, [
                  _c("span", [_vm._v("Minimum Days Before Travel:")]),
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        name: "Minimum Days Before Travel",
                        options: _vm.helper.numberArray(1, 365),
                        disabled: _vm.readOnly,
                      },
                      model: {
                        value: _vm.form.minimumDaysBeforeTravel,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "minimumDaysBeforeTravel", $$v)
                        },
                        expression: "form.minimumDaysBeforeTravel",
                      },
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(
                        _vm._s(_vm.errors.first("Minimum Days Before Travel"))
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
              _c("div", { staticClass: "vx-row mb-6" }, [
                _c("div", { staticClass: "vx-col w-full" }, [
                  _c("span", [_vm._v("Due Date Days Before Travel:")]),
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        name: "Due Date Days Before Travel",
                        options: _vm.helper.numberArray(1, 365),
                        disabled: _vm.readOnly,
                      },
                      model: {
                        value: _vm.form.dueDateDaysBeforeTravel,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "dueDateDaysBeforeTravel", $$v)
                        },
                        expression: "form.dueDateDaysBeforeTravel",
                      },
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(
                        _vm._s(_vm.errors.first("Due Date Days Before Travel"))
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
              _c(
                "div",
                { staticClass: "vx-row mb-6" },
                [
                  _c("div", { staticClass: "vx-col w-full" }, [
                    _c("span", [_vm._v("Passenger Allocations:")]),
                  ]),
                  _vm._l(
                    _vm.form.passengerAllocations,
                    function (passanger, passangerIndex) {
                      return [
                        _c(
                          "div",
                          {
                            key: passangerIndex + "0",
                            staticClass: "vx-col w-full md:w-1/4",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "mt-4" },
                              [
                                _c("label", { staticClass: "text-sm" }, [
                                  _vm._v("Adults"),
                                ]),
                                _c("vs-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value:
                                        "required|min_value:0|max_value:100",
                                      expression:
                                        "'required|min_value:0|max_value:100'",
                                    },
                                  ],
                                  staticClass: "w-full",
                                  attrs: {
                                    type: "number",
                                    min: "0",
                                    name: "adults_" + passangerIndex,
                                    disabled: _vm.readOnly,
                                  },
                                  model: {
                                    value:
                                      _vm.form.passengerAllocations[
                                        passangerIndex
                                      ].adults,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.passengerAllocations[
                                          passangerIndex
                                        ],
                                        "adults",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.passengerAllocations[passangerIndex].adults",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            key: passangerIndex + "1",
                            staticClass: "vx-col w-full md:w-1/4",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "mt-4" },
                              [
                                _c("label", { staticClass: "text-sm" }, [
                                  _vm._v("Children"),
                                ]),
                                _c("vs-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value:
                                        "required|min_value:0|max_value:100",
                                      expression:
                                        "'required|min_value:0|max_value:100'",
                                    },
                                  ],
                                  staticClass: "w-full",
                                  attrs: {
                                    min: "0",
                                    type: "number",
                                    name: "children_" + passangerIndex,
                                    disabled: _vm.readOnly,
                                  },
                                  model: {
                                    value:
                                      _vm.form.passengerAllocations[
                                        passangerIndex
                                      ].children,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.passengerAllocations[
                                          passangerIndex
                                        ],
                                        "children",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.passengerAllocations[passangerIndex].children",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            key: passangerIndex + "2",
                            staticClass: "vx-col w-full md:w-1/4",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "mt-4" },
                              [
                                _c("label", { staticClass: "text-sm" }, [
                                  _vm._v("Infants"),
                                ]),
                                _c("vs-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value:
                                        "required|min_value:0|max_value:100",
                                      expression:
                                        "'required|min_value:0|max_value:100'",
                                    },
                                  ],
                                  staticClass: "w-full",
                                  attrs: {
                                    min: "0",
                                    type: "number",
                                    name: "infants_" + passangerIndex,
                                    disabled: _vm.readOnly,
                                  },
                                  model: {
                                    value:
                                      _vm.form.passengerAllocations[
                                        passangerIndex
                                      ].infants,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.passengerAllocations[
                                          passangerIndex
                                        ],
                                        "infants",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.passengerAllocations[passangerIndex].infants",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            key: passangerIndex + "3",
                            staticClass: "vx-col w-full md:w-1/4",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "mt-3" },
                              [
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "mt-8",
                                    class: _vm.readOnly
                                      ? "opacity-50 cursor-not-allowed"
                                      : "",
                                    attrs: {
                                      size: "small",
                                      type: "border",
                                      color: "danger",
                                      disabled: _vm.readOnly,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeDynamicFields(
                                          passangerIndex,
                                          "passenger"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Remove")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    }
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full my-3" },
                    [
                      _c(
                        "vs-button",
                        {
                          class: _vm.readOnly
                            ? "opacity-50 cursor-not-allowed"
                            : "",
                          attrs: {
                            size: "small",
                            type: "border",
                            color: "success",
                            disabled: _vm.readOnly,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addDynamicFields("passenger")
                            },
                          },
                        },
                        [_vm._v("Add")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
              _c(
                "div",
                { staticClass: "vx-row mb-6" },
                [
                  _c("div", { staticClass: "vx-col w-full" }, [
                    _c("span", [_vm._v("Price Ranges:")]),
                  ]),
                  _vm._l(_vm.form.prices, function (price, priceIndex) {
                    return [
                      _c(
                        "div",
                        {
                          key: priceIndex + "0",
                          staticClass: "vx-col w-full md:w-1/3",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "mt-4" },
                            [
                              _c("label", { staticClass: "text-sm" }, [
                                _vm._v("Min"),
                              ]),
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      rules: {
                                        required: true,
                                        max_value: _vm.form.prices[priceIndex]
                                          .max
                                          ? _vm.form.prices[priceIndex].max
                                          : false,
                                      },
                                    },
                                    expression:
                                      "{\n                    rules: {\n                      required: true,\n                      max_value: form.prices[priceIndex].max\n                        ? form.prices[priceIndex].max\n                        : false,\n                    },\n                  }",
                                  },
                                ],
                                staticClass: "w-full",
                                attrs: {
                                  min: "0",
                                  type: "number",
                                  name: "priceMin_" + priceIndex,
                                  disabled: _vm.readOnly,
                                },
                                model: {
                                  value: _vm.form.prices[priceIndex].min,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.prices[priceIndex],
                                      "min",
                                      $$v
                                    )
                                  },
                                  expression: "form.prices[priceIndex].min",
                                },
                              }),
                              _vm.errors.has("priceMin_" + priceIndex)
                                ? _c(
                                    "span",
                                    { staticClass: "text-danger text-sm" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors
                                            .first("priceMin_" + priceIndex)
                                            .replace(
                                              "The priceMin_" + priceIndex,
                                              "Min price "
                                            )
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          key: priceIndex + "1",
                          staticClass: "vx-col w-full md:w-1/3",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "mt-4" },
                            [
                              _c("label", { staticClass: "text-sm" }, [
                                _vm._v("Max"),
                              ]),
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      rules: {
                                        required: true,
                                        min_value: _vm.form.prices[priceIndex]
                                          .min
                                          ? _vm.form.prices[priceIndex].min
                                          : false,
                                      },
                                    },
                                    expression:
                                      "{\n                    rules: {\n                      required: true,\n                      min_value: form.prices[priceIndex].min\n                        ? form.prices[priceIndex].min\n                        : false,\n                    },\n                  }",
                                  },
                                ],
                                staticClass: "w-full",
                                attrs: {
                                  min: "0",
                                  type: "number",
                                  name: "priceMax_" + priceIndex,
                                  disabled: _vm.readOnly,
                                },
                                model: {
                                  value: _vm.form.prices[priceIndex].max,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.prices[priceIndex],
                                      "max",
                                      $$v
                                    )
                                  },
                                  expression: "form.prices[priceIndex].max",
                                },
                              }),
                              _vm.errors.has("priceMax_" + priceIndex)
                                ? _c(
                                    "span",
                                    { staticClass: "text-danger text-sm" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors
                                            .first("priceMax_" + priceIndex)
                                            .replace(
                                              "The priceMax_" + priceIndex,
                                              "Max price "
                                            )
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          key: priceIndex + "3",
                          staticClass: "vx-col w-full md:w-1/3",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "mt-3" },
                            [
                              _c(
                                "vs-button",
                                {
                                  staticClass: "mt-8",
                                  class: _vm.readOnly
                                    ? "opacity-50 cursor-not-allowed"
                                    : "",
                                  attrs: {
                                    size: "small",
                                    type: "border",
                                    color: "danger",
                                    disabled: _vm.readOnly,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeDynamicFields(
                                        priceIndex,
                                        "priceRange"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Remove")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  }),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full my-3" },
                    [
                      _c(
                        "vs-button",
                        {
                          class: _vm.readOnly
                            ? "opacity-50 cursor-not-allowed"
                            : "",
                          attrs: {
                            size: "small",
                            type: "border",
                            color: "success",
                            disabled: _vm.readOnly,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addDynamicFields("priceRange")
                            },
                          },
                        },
                        [_vm._v("Add")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "vx-row mb-6" },
                [
                  _c("div", { staticClass: "vx-col w-full" }, [
                    _c("span", [_vm._v("Booking Dates:")]),
                  ]),
                  _vm._l(
                    _vm.form.bookingDates,
                    function (bookingDate, dateIndex) {
                      return [
                        _c(
                          "div",
                          {
                            key: dateIndex + "0",
                            staticClass: "vx-col w-full md:w-1/5",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "mt-4" },
                              [
                                _c("label", { staticClass: "text-sm" }, [
                                  _vm._v("Min"),
                                ]),
                                _c("flat-pickr", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass: "w-full",
                                  attrs: {
                                    name: "bookMin_" + dateIndex,
                                    config: {
                                      dateFormat: "Y-m-d",
                                      maxDate:
                                        _vm.form.bookingDates[dateIndex].max,
                                    },
                                    disabled: _vm.readOnly,
                                  },
                                  model: {
                                    value: _vm.form.bookingDates[dateIndex].min,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.bookingDates[dateIndex],
                                        "min",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.bookingDates[dateIndex].min",
                                  },
                                }),
                                _vm.errors.has("bookMin_" + dateIndex)
                                  ? _c(
                                      "span",
                                      { staticClass: "text-danger text-sm" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.errors
                                              .first("bookMin_" + dateIndex)
                                              .replace(
                                                "The bookMin_" +
                                                  dateIndex +
                                                  " field is required",
                                                "Min date is required"
                                              )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            key: dateIndex + "1",
                            staticClass: "vx-col w-full md:w-1/5",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "mt-4" },
                              [
                                _c("label", { staticClass: "text-sm" }, [
                                  _vm._v("Max"),
                                ]),
                                _c("flat-pickr", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass: "w-full",
                                  attrs: {
                                    config: {
                                      dateFormat: "Y-m-d",
                                      minDate:
                                        _vm.form.bookingDates[dateIndex].min,
                                    },
                                    name: "bookMax_" + dateIndex,
                                    disabled: _vm.readOnly,
                                  },
                                  model: {
                                    value: _vm.form.bookingDates[dateIndex].max,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.bookingDates[dateIndex],
                                        "max",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.bookingDates[dateIndex].max",
                                  },
                                }),
                                _vm.errors.has("bookMax_" + dateIndex)
                                  ? _c(
                                      "span",
                                      { staticClass: "text-danger text-sm" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.errors
                                              .first("bookMax_" + dateIndex)
                                              .replace(
                                                "The bookMax_" +
                                                  dateIndex +
                                                  " field is required",
                                                "Max date is required"
                                              )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            key: dateIndex + "2",
                            staticClass: "vx-col w-full md:w-2/5",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "mt-4" },
                              [
                                _c("label", { staticClass: "text-sm" }, [
                                  _vm._v("Days"),
                                ]),
                                _c("v-select", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: {
                                        rules: { required: false },
                                      },
                                      expression:
                                        "{\n                    rules: { required: false },\n                  }",
                                    },
                                  ],
                                  staticClass: "w-full",
                                  attrs: {
                                    name: "daysOfWeek",
                                    multiple: "",
                                    options: _vm.helper.enums.daysOfWeek,
                                    reduce: (option) => option.code,
                                    disabled: _vm.readOnly,
                                  },
                                  model: {
                                    value:
                                      _vm.form.bookingDates[dateIndex]
                                        .daysOfWeek,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.bookingDates[dateIndex],
                                        "daysOfWeek",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.bookingDates[dateIndex].daysOfWeek",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            key: dateIndex + "3",
                            staticClass: "vx-col w-full md:w-1/5",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "mt-3" },
                              [
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "mt-8",
                                    class: _vm.readOnly
                                      ? "opacity-50 cursor-not-allowed"
                                      : "",
                                    attrs: {
                                      size: "small",
                                      type: "border",
                                      color: "danger",
                                      disabled: _vm.readOnly,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeDynamicFields(
                                          dateIndex,
                                          "bookingDates"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Remove")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    }
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full my-3" },
                    [
                      _c(
                        "vs-button",
                        {
                          class: _vm.readOnly
                            ? "opacity-50 cursor-not-allowed"
                            : "",
                          attrs: {
                            size: "small",
                            type: "border",
                            color: "success",
                            disabled: _vm.readOnly,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addDynamicFields("bookingDates")
                            },
                          },
                        },
                        [_vm._v("Add")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "vx-row mb-6" },
                [
                  _c("div", { staticClass: "vx-col w-full" }, [
                    _c("span", [_vm._v("Travel Dates:")]),
                  ]),
                  _vm._l(
                    _vm.form.travelDates,
                    function (travelDate, dateIndex) {
                      return [
                        _c(
                          "div",
                          {
                            key: dateIndex + "0",
                            staticClass: "vx-col w-full md:w-1/5",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "mt-4" },
                              [
                                _c("label", { staticClass: "text-sm" }, [
                                  _vm._v("Min"),
                                ]),
                                _c("flat-pickr", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass: "w-full",
                                  attrs: {
                                    config: {
                                      dateFormat: "Y-m-d",
                                      maxDate:
                                        _vm.form.travelDates[dateIndex].max,
                                    },
                                    name: "travelMin_" + dateIndex,
                                    disabled: _vm.readOnly,
                                  },
                                  model: {
                                    value: _vm.form.travelDates[dateIndex].min,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.travelDates[dateIndex],
                                        "min",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.travelDates[dateIndex].min",
                                  },
                                }),
                                _vm.errors.has("travelMin_" + dateIndex)
                                  ? _c(
                                      "span",
                                      { staticClass: "text-danger text-sm" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.errors
                                              .first("travelMin_" + dateIndex)
                                              .replace(
                                                "The travelMin_" +
                                                  dateIndex +
                                                  " field is required",
                                                "Min date is required"
                                              )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            key: dateIndex + "1",
                            staticClass: "vx-col w-full md:w-1/5",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "mt-4" },
                              [
                                _c("label", { staticClass: "text-sm" }, [
                                  _vm._v("Max"),
                                ]),
                                _c("flat-pickr", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass: "w-full",
                                  attrs: {
                                    config: {
                                      dateFormat: "Y-m-d",
                                      minDate:
                                        _vm.form.travelDates[dateIndex].min,
                                    },
                                    name: "travelMax_" + dateIndex,
                                    disabled: _vm.readOnly,
                                  },
                                  model: {
                                    value: _vm.form.travelDates[dateIndex].max,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.travelDates[dateIndex],
                                        "max",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.travelDates[dateIndex].max",
                                  },
                                }),
                                _vm.errors.has("travelMax_" + dateIndex)
                                  ? _c(
                                      "span",
                                      { staticClass: "text-danger text-sm" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.errors
                                              .first("travelMax_" + dateIndex)
                                              .replace(
                                                "The travelMax_" +
                                                  dateIndex +
                                                  " field is required",
                                                "Max date is required"
                                              )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            key: dateIndex + "2",
                            staticClass: "vx-col w-full md:w-2/5",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "mt-4" },
                              [
                                _c("label", { staticClass: "text-sm" }, [
                                  _vm._v("Days"),
                                ]),
                                _c("v-select", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: {
                                        rules: { required: false },
                                      },
                                      expression:
                                        "{\n                    rules: { required: false },\n                  }",
                                    },
                                  ],
                                  staticClass: "w-full",
                                  attrs: {
                                    name: "daysOfWeek",
                                    multiple: "",
                                    options: _vm.helper.enums.daysOfWeek,
                                    reduce: (option) => option.code,
                                    disabled: _vm.readOnly,
                                  },
                                  model: {
                                    value:
                                      _vm.form.travelDates[dateIndex]
                                        .daysOfWeek,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.travelDates[dateIndex],
                                        "daysOfWeek",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.travelDates[dateIndex].daysOfWeek",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            key: dateIndex + "3",
                            staticClass: "vx-col w-full md:w-1/5",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "mt-3" },
                              [
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "mt-8",
                                    class: _vm.readOnly
                                      ? "opacity-50 cursor-not-allowed"
                                      : "",
                                    attrs: {
                                      size: "small",
                                      type: "border",
                                      color: "danger",
                                      disabled: _vm.readOnly,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeDynamicFields(
                                          dateIndex,
                                          "travelDates"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Remove")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    }
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full my-3" },
                    [
                      _c(
                        "vs-button",
                        {
                          class: _vm.readOnly
                            ? "opacity-50 cursor-not-allowed"
                            : "",
                          attrs: {
                            size: "small",
                            type: "border",
                            color: "success",
                            disabled: _vm.readOnly,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addDynamicFields("travelDates")
                            },
                          },
                        },
                        [_vm._v("Add")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "vx-row mb-6" },
            [
              _c("div", { staticClass: "vx-col w-full" }, [
                _c("span", [_vm._v("Search Types: (required)")]),
              ]),
              _vm._l(
                _vm.helper.enums.searchTypes,
                function (searchType, searchIndex) {
                  return [
                    _c(
                      "div",
                      {
                        key: searchIndex,
                        staticClass: "vx-col flex flex-wrap w-1/2 mb-4 mt-4",
                      },
                      [
                        _c(
                          "vs-checkbox",
                          {
                            attrs: {
                              "vs-value": searchType.value,
                              title: searchType,
                              disabled: _vm.readOnly,
                            },
                            model: {
                              value: _vm.form.searchTypes,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "searchTypes", $$v)
                              },
                              expression: "form.searchTypes",
                            },
                          },
                          [_vm._v(_vm._s(searchType.displayName))]
                        ),
                      ],
                      1
                    ),
                  ]
                }
              ),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "vx-row mb-6" },
            [
              _c("div", { staticClass: "vx-col w-full" }, [
                _c("span", [_vm._v("Product Types: (required)")]),
              ]),
              _vm._l(
                _vm.helper.enums.productTypes,
                function (product, productIndex) {
                  return [
                    _c(
                      "div",
                      {
                        key: productIndex,
                        staticClass: "vx-col flex flex-wrap w-1/2 mb-4 mt-4",
                      },
                      [
                        _c(
                          "vs-checkbox",
                          {
                            attrs: {
                              "vs-value": product.value,
                              title: product,
                              disabled: _vm.readOnly,
                            },
                            model: {
                              value: _vm.form.productTypes,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "productTypes", $$v)
                              },
                              expression: "form.productTypes",
                            },
                          },
                          [_vm._v(_vm._s(product.displayName))]
                        ),
                      ],
                      1
                    ),
                    _c(product.componentName, {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            product.componentName &&
                            _vm.form.productTypes.includes(product.value),
                          expression:
                            "\n            product.componentName && form.productTypes.includes(product.value)\n          ",
                        },
                      ],
                      key: productIndex + "1",
                      ref: _vm.pricingRuleChildComponent,
                      refInFor: true,
                      tag: "component",
                      staticClass: "vx-col w-full",
                      attrs: {
                        form: _vm.form,
                        helper: _vm.helper,
                        priceRulesFormData: _vm.priceRulesFormData,
                        readOnly: _vm.readOnly,
                      },
                    }),
                  ]
                }
              ),
            ],
            2
          ),
          _c("div", { staticClass: "lg:float-left mt-4" }, [
            _c("span", { staticClass: "text-sm text-danger" }, [
              _vm._v("*Required Field"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-end" },
            [
              !_vm.readOnly
                ? _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto mt-4 mr-4",
                      attrs: { color: "danger" },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v("Cancel")]
                  )
                : _vm._e(),
              !_vm.readOnly
                ? _c(
                    "vs-button",
                    { staticClass: "mt-4", on: { click: _vm.handleSubmit } },
                    [_vm._v("Save")]
                  )
                : _vm._e(),
              _vm.readOnly && !_vm.isSystemRole
                ? _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto mt-4 mr-4",
                      on: { click: _vm.handleEditMode },
                    },
                    [_vm._v("Edit")]
                  )
                : _vm._e(),
              _vm.readOnly
                ? _c(
                    "vs-button",
                    { staticClass: "mt-4", on: { click: _vm.handleCancel } },
                    [_vm._v("Back")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "vs-prompt",
            {
              attrs: {
                title: "",
                "accept-text": "Proceed",
                active: _vm.displayProductTypeWarning,
              },
              on: {
                cancel: function ($event) {
                  _vm.proceedSaving = false
                },
                close: function ($event) {
                  _vm.proceedSaving = false
                },
                accept: function ($event) {
                  _vm.proceedSaving = true
                  _vm.handleSubmit()
                },
                "update:active": function ($event) {
                  _vm.displayProductTypeWarning = $event
                },
              },
            },
            [
              _c("div", { staticClass: "vx-row mt-2 mb-4" }, [
                _c("div", { staticClass: "vx-col" }, [
                  _c("p", [
                    _vm._v(
                      "\n            Not all of the additional criterias have been set up for following\n            product types\n          "
                    ),
                  ]),
                  _c("br"),
                  _c("p", [
                    _c("strong", [
                      _vm._v(
                        " " + _vm._s(_vm.productTypesWithoutRule.join()) + " "
                      ),
                    ]),
                  ]),
                  _c("br"),
                  _c("p", [
                    _vm._v("If you want to save anyway, click proceed button"),
                  ]),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }